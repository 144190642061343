import api from '@/api';
/* eslint no-shadow: ["error", { "allow": ["state"] }] */

// initial state
const state = {
  all: [
    {
      id: 1,
      name: 'batiments',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs principaux',
          fields: [
            {
              name: 'images',
              label: 'images',
              type: 'picturesDisplay',
            },
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'adresse',
              label: 'Adresse',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'typeUsage',
              label: 'Type d\'usage',
              type: 'select',
              rules: 'necessary',
              options: '/api/ref/liste-options?codeType=ref_typeusage',
              placeholder: '',
            },
            {
              name: 'typeUsageDetaille',
              label: 'Type d\'usage détaillé',
              type: 'typeUsage',
              placeholder: '',
              interaction: 'if:typeUsage',
            },
            {
              name: 'site',
              label: 'Site',
              type: 'select',
              readOnly: true,
              options: 'allMissionSites',
              rules: 'required',
              placeholder: '',
            },
            {
              name: 'anneeConstruction',
              label: 'Année de construction',
              type: 'number',
              rules: 'length:4|minVal:0|integer',
              placeholder: '',
            },
            {
              name: 'idClient',
              label: 'Code du bâtiment',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
          ],
        },
        {
          name: 'technique',
          label: 'Attributs techniques',
          fields: [
            {
              name: 'typeSurface',
              label: 'Type de surface',
              type: 'btnGroup',
              options: [
                'SHON',
                'SU',
              ],
              default: 'SHON',
            },
            {
              name: 'surfaceDeclaree',
              label: 'Surface Déclarée',
              type: 'number',
              rules: 'necessary|minVal:0',
              placeholder: '',
            },
            {
              name: 'surface2',
              label: 'Surface plancher',
              type: 'number',
              rules: 'necessary|minVal:-1',
              placeholder: '',
            },
            {
              name: 'surfaceChauffee',
              label: 'Surface chauffée',
              type: 'number',
              rules: 'necessary|minVal:0',
              placeholder: '',
            },
            {
              name: 'nbNiveau',
              label: 'Nombre de niveaux',
              type: 'number',
              rules: 'minEqVal:1|integer',
              placeholder: '',
            },
            {
              name: 'nbOccupants',
              label: 'Nombre d\'occupants',
              type: 'number',
              rules: 'minVal:0|integer',
              placeholder: '',
            },
            {
              name: 'presenceErp',
              label: 'ERP?',
              type: 'booleanRadio',
              placeholder: '',
              default: false,
            },
            {
              name: 'typeERP',
              label: 'Type d\'Erp',
              type: 'menuSelect',
              options: [
                { header: 'Établissements installés dans un bâtiment' },
                { name: 'J : Structures d’accueil pour personnes âgées ou personnes handicapées' },
                { name: 'L : Salles d\'auditions, de conférences, de réunions, de spectacles ou à usage multiple' },
                { name: 'M : Magasins de vente, centres commerciaux' },
                { name: 'N : Restaurants et débits de boisson' },
                { name: 'O : Hôtels et autres établissements d’hébergement' },
                { name: 'P : Salles de danse et salles de jeux' },
                { name: 'R : Établissements d’éveil, d’enseignement, de formation, centres de vacances, centres de loisirs sans hébergement' },
                { name: 'S : Bibliothèques, centres de documentation' },
                { name: 'T : Salles d\'exposition à vocation commerciale' },
                { name: 'U : Établissements de soins' },
                { name: 'V : Établissements de divers cultes' },
                { name: 'W : Administrations, banques, bureaux' },
                { name: 'X : Établissements sportifs couverts' },
                { name: 'Y : Musées' },
                { divider: true },
                { header: 'Établissements spéciaux' },
                { name: 'PA : Établissements de Plein Air' },
                { name: 'CTS : Chapiteaux, Tentes et Structures toile' },
                { name: 'SG : Structures Gonflables' },
                { name: 'PS : Parcs de Stationnement couverts' },
                { name: 'OA : Hôtels-restaurants d\'Altitude' },
                { name: 'GA : Gares Accessibles au public (chemins de fer, téléphériques, remonte-pentes…)' },
                { name: 'EF : Établissements flottants (eaux intérieures)' },
                { name: 'REF : Refuges des montagnes' },
              ],
              placeholder: '',
              interaction: 'if:presenceErp',
            },
            {
              name: 'categorieERP',
              label: 'Catégorie d\'Erp',
              type: 'select',
              options: [
                '1re catégorie',
                '2e catégorie',
                '3e catégorie',
                '4e catégorie',
                '5e catégorie',
              ],
              placeholder: '',
              interaction: 'if:presenceErp',
            },
            {
              name: 'contraintesArchi',
              label: 'Contraintes architecturale?',
              type: 'booleanRadio',
              placeholder: '',
              default: false,
            },
            {
              name: 'descriptionArchi',
              label: 'Description',
              type: 'textArea',
              rules: 'maxLength:650',
              placeholder: '',
              interaction: 'if:contraintesArchi',
            },
            {
              name: 'coordGPS',
              label: 'Coordonnées GPS',
              type: 'gpsField',
              rules: 'necessary',
              placeholder: '',
            },
            {
              name: 'energiesChauffage',
              label: 'Energies de chauffage',
              type: 'multiselect',
              options: '/api/ref/liste-options?codeType=energie',
              placeholder: '',
            },
            {
              name: 'hasSurfacePreferee',
              label: 'Définir des métrés préférés ?',
              type: 'booleanRadio',
              default: false,
            },
            {
              name: 'plancherBas',
              label: 'Surface plancher bas',
              type: 'number',
              unit: 'm2',
              rules: 'integer',
              interaction: 'if:hasSurfacePreferee',
            },
            {
              name: 'plancherHaut',
              label: 'Surface plancher haut',
              type: 'number',
              unit: 'm2',
              hint: 'y compris surfaces vitrées',
              rules: 'integer',
              interaction: 'if:hasSurfacePreferee',
            },
            {
              name: 'paroiOpaque',
              label: 'Paroi opaque verticale',
              type: 'number',
              unit: 'm2',
              rules: 'integer',
              interaction: 'if:hasSurfacePreferee',
            },
            {
              name: 'paroiVitree',
              label: 'Paroi vitrée verticale',
              type: 'number',
              unit: 'm2',
              rules: 'integer',
              interaction: 'if:hasSurfacePreferee',
            },
            {
              name: 'volumePrefere',
              label: 'Volume',
              type: 'number',
              unit: 'm3',
              rules: 'integer',
              interaction: 'if:hasSurfacePreferee',
            },
          ],
        },
        {
          name: 'visite',
          label: 'Organisation de la visite',
          fields: [
            {
              name: 'dateReleve',
              label: 'Date de relevé',
              type: 'date',
              readOnly: false,
              rules: 'necessary',
            },
            {
              name: 'auditeur',
              label: 'QUAD auditeur',
              type: 'text',
              rules: 'necessary|length:4|isUppercase',
              placeholder: '',
            },
            {
              name: 'commentaireVisite',
              label: 'Commentaire',
              type: 'textArea',
              rules: 'maxLength:650',
              placeholder: '',
              lines: 3,
            },
            {
              name: 'accompagnant',
              label: 'Présence d\'un accompagnant?',
              type: 'booleanRadio',
              placeholder: '',
              default: false,
            },
            {
              name: 'identAccompagnant',
              label: 'Nom de l\'accompagnant',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
              interaction: 'if:accompagnant',
            },
            {
              name: 'organismeAccompagnant',
              label: 'Organisme',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
              interaction: 'if:accompagnant',
            },
            {
              name: 'fonctionAccompagnant',
              label: 'fonction de  l\'accompagnant',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
              interaction: 'if:accompagnant',
            },
          ],
        },
        {
          name: 'travaux',
          label: 'Historique des travaux',
          fields: [
            {
              nestedObj: '',
              name: 'rehabilitation',
              label: 'Historique des travaux',
              type: 'displayWorkHistory',
              readOnly: true,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: 'niveaux',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs principaux',
          fields: [
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'perimetre',
              label: 'Périmètre déperditif',
              type: 'number',
              rules: 'required|minEqVal:0',
              unit: 'm',
              placeholder: '',
            },
            {
              name: 'hsp',
              label: 'Hauteur sous-plafond',
              type: 'number',
              rules: 'required|minEqVal:0',
              unit: 'm',
              placeholder: '',
            },
            {
              name: 'txPbExt',
              label: 'Taux plancher bas sur extérieur',
              type: 'number',
              rules: 'required|minEqVal:0|maxEqVal:100',
              unit: '%',
              placeholder: '',
            },
            {
              name: 'txPhExt',
              label: 'Taux plancher haut sur extérieur',
              type: 'number',
              rules: 'required|minEqVal:0|maxEqVal:100',
              unit: '%',
              placeholder: '',
            },
            {
              name: 'txSv',
              label: 'Taux de surface vitrée',
              type: 'number',
              rules: 'required|minEqVal:0|maxEqVal:100',
              unit: '%',
              placeholder: '',
            },
          ],
        },
        {
          name: 'calcule',
          label: 'Attributs calculés',
          fields: [
            {
              nestedObj: 'calculations',
              name: 'surfaceChauffee',
              label: 'Surface niveau',
              type: 'display',
              unit: 'm2',
              readOnly: true,
            },
            {
              nestedObj: 'calculations',
              name: 'surfacePlancherBas',
              label: 'Surface de plancher bas extérieur',
              type: 'display',
              unit: 'm2',
              readOnly: true,
            },
            {
              nestedObj: 'calculations',
              name: 'surfaceParoiVerticaleOpaque',
              label: 'Surface de parois verticales opaques',
              type: 'display',
              unit: 'm2',
              readOnly: true,
            },
            {
              nestedObj: 'calculations',
              name: 'surfacePlancherHaut',
              label: 'Surface de plancher haut extérieur',
              type: 'display',
              unit: 'm2',
              readOnly: true,
            },
            {
              nestedObj: 'calculations',
              name: 'surfaceParoiVerticale',
              label: 'Surface de parois verticales',
              type: 'display',
              unit: 'm2',
              readOnly: true,
            },
            {
              nestedObj: 'calculations',
              name: 'surfaceParoiVerticaleVitree',
              label: 'Surface de vitrage',
              type: 'display',
              unit: 'm2',
              readOnly: true,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: 'equipements',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs généraux',
          fields: [
            {
              name: 'images',
              label: 'images',
              type: 'picturesDisplay',
            },
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'niveau',
              label: 'Localisation niveau',
              type: 'select',
              options: 'batimentNiveaux',
              placeholder: '',
            },
            {
              name: 'local',
              label: 'Localisation local',
              type: 'selectLearning',
              options: 'batimentLocaux',
              placeholder: '',
            },
            {
              name: 'description',
              label: 'Description',
              type: 'textAreaEditor',
              rules: 'maxLength:1000',
              placeholder: 'Décrivez l\'objet en quelques lignes',
              lines: 3,
            },
          ],
        },
        {
          name: 'performance',
          label: 'Attributs de performance',
          fields: [
            {
              name: 'performance',
              label: 'Performance',
              type: 'select',
              options: [
                { libelle: '0-Très énergivore', codeOption: '0-performance' },
                { libelle: '1-Energivore', codeOption: '1-performance' },
                { libelle: '2-Performant', codeOption: '2-performance' },
                { libelle: '3-Très performant', codeOption: '3-performance' },
                { libelle: 'NC- Non concerné', codeOption: 'NC-performance' },
              ],
              placeholder: '',
              rules: 'necessary',
            },
            {
              name: 'vetuste',
              label: 'Vétusté',
              type: 'select',
              options: [
                { libelle: '0- À remplacer', codeOption: '0- À remplacer' },
                { libelle: '1- État d\'usage', codeOption: '1- État d\'usage' },
                { libelle: '2- Bon état', codeOption: '2- Bon état' },
                { libelle: '3- État neuf', codeOption: '3- État neuf' },
                { libelle: 'NC- Non concerné', codeOption: 'NC- Non concerné' },
              ],
              placeholder: '',
              rules: 'necessary',
            },
          ],
        },
      ],
    },
    {
      id: 4,
      name: 'clients',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs principaux',
          fields: [
            {
              name: 'image',
              label: 'Logo client',
              type: 'picture',
              placeholder: '',
            },
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'adresse',
              label: 'Adresse',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'codePostal',
              label: 'Code postal',
              type: 'text',
              rules: 'length:5',
              placeholder: '',
            },
            {
              name: 'ville',
              label: 'Ville',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'serviceDemandeur',
              label: 'Service demandeur',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'nomContact',
              label: 'Nom du contact',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'prenomContact',
              label: 'Prenom du contact',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'fonctionContact',
              label: 'Fonction du contact',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'telContact',
              label: 'Telephone du contact',
              type: 'text',
              placeholder: '',
              rules: 'phone|maxLength:255',
            },
            {
              name: 'mailContact',
              label: 'Mail du contact',
              type: 'text',
              placeholder: '',
              rules: 'email|maxLength:255',
            },
          ],
        },
      ],
    },
    {
      id: 5,
      name: 'missions',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs principaux',
          fields: [
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'client',
              label: 'Client',
              type: 'select',
              options: 'allClients',
              rules: 'required|maxLength:255|create-only',
              placeholder: '',
            },
            {
              name: 'typesAudits',
              label: 'Types D\'audits',
              type: 'multiselect',
              options: 'allTypesAudits',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'codeRP',
              label: 'Code RP',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'refVersion',
              label: 'Version de référentiel',
              type: 'select',
              rules: 'required',
              options: 'allRefs',
              placeholder: '',
              permission: 'ROLE_ADMIN_GENERAL',
              hint: 'Ce champ n\'est modifiable que par un admin général',
            },
            {
              name: 'createdBy',
              label: 'Créé par',
              type: 'text',
              readOnly: true,
              rules: 'maxLength:255|create-only',
              placeholder: '',
            },
            {
              name: 'agence',
              label: 'Société',
              type: 'select',
              options: ['Alterea', 'Cyrisea', 'Aveltys'],
              default: 'Alterea',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
          ],
        },
        {
          name: 'general',
          label: 'Paramètres',
          fields: [
            {
              name: 'coeffPonderation',
              label: 'Coefficient de pondération des investissements',
              type: 'number',
              rules: 'maxLength:20|required',
              placeholder: '',
              default: 1,
            },
            {
              name: 'txActualisationEnergies',
              label: 'Taux d\'actualisation des énergies',
              type: 'number',
              unit: '%',
              rules: 'maxLength:20|required',
              placeholder: '',
              default: 4,
            },
            {
              name: 'txTVA',
              label: 'Taux TVA',
              type: 'number',
              unit: '%',
              rules: 'maxLength:20|required',
              placeholder: '',
              default: 20,
            },
            {
              name: 'prixCEE',
              label: 'Prix du CEE',
              type: 'number',
              unit: '€/kWh cumac',
              rules: 'maxLength:20|required',
              placeholder: '',
              default: 0.007,
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: 'sites',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs principaux',
          fields: [
            {
              name: 'image',
              label: 'Image',
              type: 'picture',
              placeholder: '',
            },
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'adresse',
              label: 'Adresse',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'codePostal',
              label: 'Code postal',
              type: 'text',
              rules: 'required|length:5',
              placeholder: '',
            },
            {
              name: 'ville',
              label: 'Commune',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'codeSite',
              label: 'Code du site',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'temperatureBaseExterieur',
              label: 'Température de base extérieur',
              type: 'number',
              rules: 'required',
              placeholder: '',
              default: -4,
            },
            {
              name: 'temperatureBaseInterieur',
              label: 'Température de base intérieur',
              type: 'number',
              rules: 'required',
              placeholder: '',
              default: 19,
            },
            {
              name: 'concernedByBacs',
              label: 'Assujeti au décret BACS',
              type: 'selectBoolean',
              options: [true, false],
              readOnly: true,
              placeholder: '',
            },
            {
              name: 'hasBacsPrefere',
              label: 'Choisir le décret BACS',
              type: 'booleanRadio',
              placeholder: '',
              default: false,
            },
            {
              name: 'valeurBacsPrefere',
              label: 'Assujettissement bacs prefere',
              type: 'selectBoolean',
              placeholder: '',
              default: false,
              interaction: 'if:hasBacsPrefere',
            },
          ],
        },
      ],
    },
    {
      id: 7,
      name: 'inspections',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs généraux',
          fields: [
            {
              name: 'images',
              label: 'images',
              type: 'picturesDisplay',
            },
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
          ],
        },
      ],
    },
    {
      id: 8,
      name: 'compteurs',
      fieldGroups: [
        {
          name: 'general',
          fields: [
            {
              name: 'nomCompteur',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'numeroCompteur',
              label: 'Numéro',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'adresse',
              label: 'Adresse',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'energie',
              label: 'Energie',
              type: 'select',
              rules: 'required',
              options: '/api/ref/liste-options?codeType=energie',
              placeholder: '',
            },
            {
              name: 'client',
              label: 'Client',
              type: 'select',
              readOnly: true,
              options: 'allClients',
              rules: 'required',
              placeholder: '',
            },
          ],
        },
      ],
    },
    {
      id: 9,
      name: 'imageCatalogues',
      fieldGroups: [
        {
          name: 'general',
          fields: [
            {
              name: 'image',
              label: 'Image',
              type: 'picture',
              placeholder: '',
            },
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'codeImageCatalogue',
              label: 'Code',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
          ],
        },
      ],
    },
    {
      id: 10,
      name: 'patrimoines',
      fieldGroups: [
        {
          name: 'general',
          label: 'Attributs principaux',
          fields: [
            {
              name: 'nom',
              label: 'Nom',
              type: 'text',
              rules: 'required|maxLength:255',
              placeholder: '',
            },
            {
              name: 'client',
              label: 'Client',
              type: 'select',
              options: 'allClients',
              rules: 'required|maxLength:255|create-only',
              placeholder: '',
            },
            {
              name: 'numero',
              label: 'Numéro',
              type: 'number',
              rules: 'required|minVal:0',
              placeholder: '',
            },
            {
              name: 'description',
              label: 'Description',
              type: 'textArea',
              rules: 'maxLength:650',
              placeholder: '',
            },
          ],
        },
      ],
    },
    {
      id: 11,
      name: 'factures',
      fieldGroups: [
        {
          name: 'general',
          fields: [
            {
              name: 'numerofacture',
              label: 'Numéro facture',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
              default: '',
            },
            {
              name: 'dateDebut',
              label: 'Date de début',
              type: 'date',
              rules: 'required',
              placeholder: '',
            },
            {
              name: 'dateFin',
              label: 'Date de fin',
              type: 'date',
              rules: 'required',
              placeholder: '',
            },
            {
              name: 'consommationEf',
              label: 'Consommation',
              type: 'number',
              rules: 'required',
              placeholder: '',
              unit: 'kWhEF PCI',
            },
            {
              name: 'coutTotal',
              label: 'Coût total',
              type: 'number',
              rules: 'required',
              placeholder: '',
              unit: '€TTC',
            },
            {
              name: 'coutAbonnement',
              label: 'Coût abonnement',
              type: 'number',
              placeholder: '',
              unit: '€TTC',
            },
            {
              name: 'numerofactureDeReference',
              label: 'Numéro facture de référence',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
            },
            {
              name: 'sourceDonnee',
              label: 'Source de données',
              type: 'text',
              rules: 'maxLength:255',
              placeholder: '',
              default: 'SAISIE',
            },
          ],
        },
      ],
    },
  ],
  equipementTypes: [
  ],
  inspectionsTypes: [
  ],
};

// getters
const getters = {
  getFormTemplate(state) {
    return (itemType, item) => {
      const generalForm = state.all.find(form => form.name === itemType);

      // make a copy of the formTemplate
      const formCopy = {
        id: generalForm.id,
        name: generalForm.name,
        fieldGroups: [...generalForm.fieldGroups],
      };

      if (itemType === 'inspections') {
        const inspectionTypeForm = state.inspectionsTypes
          .find(form => form.id === item.inspectionType);
        if (inspectionTypeForm) {
          formCopy.fieldGroups = [...formCopy.fieldGroups, ...inspectionTypeForm.formTemplate];
        }
      }

      return formCopy;
    };
  },

  getEquipementFormTemplate(state) {
    return (item, missionId) => {
      const generalForm = state.all.find(form => form.name === 'equipements');

      // make a copy of the formTemplate
      const formCopy = {
        id: generalForm.id,
        name: generalForm.name,
        fieldGroups: [...generalForm.fieldGroups],
      };

      // get the equipementType template
      // and merge it on the formCopy
      const shortType = item.type.slice(item.type.lastIndexOf('/') + 1);
      const equipementTypeForm = state.equipementTypes.find(form => form.id === `${missionId}_${shortType}_form`);
      if (equipementTypeForm) {
        // exchange position between performance et specifique
        const insertLast = formCopy.fieldGroups.pop();
        formCopy.fieldGroups = [...formCopy
          .fieldGroups, ...equipementTypeForm.formTemplate, insertLast];
      }

      return formCopy;
    };
  },

};


// actions
const actions = {
  fetchEquipementTemplate({ commit }, { type = '', missionId = '' }) {
    return api.equipementForms.getByType(missionId, type)
      .then((response) => {
        commit('addEquipementTemplates', [response]);
        return response;
      });
  },

  loadOffline({ commit }) {
    return api.equipementForms.loadOffline()
      .then(response => commit('addEquipementTemplates', response));
  },

  fetchInspectionTemplate({ commit }, type) {
    return api.inspectionTypeForms.getByType(type)
      .then((response) => {
        commit('addInspectionTemplates', [response]);
        return response;
      });
  },
};

// mutations
const mutations = {
  addEquipementTemplates(state, newEquipementTemplates) {
    newEquipementTemplates.forEach((newEquipementTemplate) => {
      const stateIndex = state.equipementTypes
        .findIndex(stateTemplate => stateTemplate.id === newEquipementTemplate.id);

      if (stateIndex >= 0) {
        state.equipementTypes.splice(stateIndex, 1, newEquipementTemplate);
      } else {
        state.equipementTypes.push(newEquipementTemplate);
      }
    });
  },

  addInspectionTemplates(state, newInspectionTemplates) {
    newInspectionTemplates.forEach((newInspectionTemplate) => {
      const stateIndex = state.inspectionsTypes
        .findIndex(stateTemplate => stateTemplate.id === newInspectionTemplate.id);

      if (stateIndex >= 0) {
        state.inspectionsTypes.splice(stateIndex, 1, newInspectionTemplate);
      } else {
        state.inspectionsTypes.push(newInspectionTemplate);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
